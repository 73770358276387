import * as React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
    <Layout>
        <div>
            <h1>Seite nicht gefunden</h1>
            <p>Sorry... Das ging wohl schief... Aber so ist das im Leben nun mal. Manchmal läuft es nicht ganz wie
                geplant</p>
        </div>
    </Layout>
);

export default NotFoundPage;
